import {
  ApiOptions,
  CreateRequestPayload,
  GetJobResponse,
  IAPIClient,
  TrackDownloadThumbnailEventPayload
} from "./APIClient";

export class APIClientError extends Error {
  constructor(message: string, public status: number) {
    super(message);
    this.name = 'APIClientError';
    this.status = status;
  }
}

export class APIClientImpl implements IAPIClient {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private createHeaders(options?: ApiOptions): Headers {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    if (options?.prefer) {
      headers.append('Prefer', options.prefer);
    }

    return headers;
  }

  async createRequest(payload: CreateRequestPayload, options?: ApiOptions): Promise<string> {
    const response = await fetch(`${this.baseUrl}/v1/world_thumbnail_proposal/jobs`, {
      method: 'POST',
      headers: this.createHeaders(options),
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new APIClientError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.jobId;
  }

  async getJob(jobId: string, options?: ApiOptions): Promise<GetJobResponse> {
    const response = await fetch(`${this.baseUrl}/v1/world_thumbnail_proposal/jobs/${jobId}`, {
      headers: this.createHeaders(options),
    });

    if (!response.ok) {
      throw new APIClientError(`HTTP error! status: ${response.status}`, response.status);
    }

    return await response.json();
  }

  async trackDownloadThumbnailEvent(payload: TrackDownloadThumbnailEventPayload, options?: ApiOptions): Promise<void> {
    const response = await fetch(`${this.baseUrl}/v1/world_thumbnail_proposal/events/download_thumbnail`, {
      method: 'POST',
      headers: this.createHeaders(options),
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new APIClientError(`HTTP error! status: ${response.status}`, response.status);
    }
  }
}