import {
  ApiOptions,
  CreateRequestPayload,
  GetJobResponse,
  IAPIClient,
  TrackDownloadThumbnailEventPayload
} from "./APIClient";


export class MockAPIClient implements IAPIClient {
  private apiClient: IAPIClient;
  private lastRequestIssuedAt: number = 0;

  constructor(apiClient: IAPIClient) {
    this.apiClient = apiClient;
  }

  async createRequest(payload: CreateRequestPayload, options?: ApiOptions): Promise<string> {
    // ここでモック固有の処理を追加できます（例：ログ出力、バリデーションなど）
    console.log('MockAPIClient: createRequest called', { payload, options });
    this.lastRequestIssuedAt = Date.now();
    return this.apiClient.createRequest(payload, options);
  }

  async getJob(jobId: string, options?: ApiOptions): Promise<GetJobResponse> {
    const timeSinceLastRequest = Date.now() - this.lastRequestIssuedAt;
    let preferHeader: string | undefined;
    if (options?.prefer !== undefined) {
      preferHeader = options.prefer;
    } else if (timeSinceLastRequest > 5000) {
      preferHeader = "example=success";
    } else if (timeSinceLastRequest > 2500) {
      preferHeader = "example=in_progress";
    } else {
      preferHeader = "example=queued";
    }
    let options1 = {prefer: preferHeader};
    console.log('MockAPIClient: getJob called', { jobId, options1 });
    return this.apiClient.getJob(jobId, options1);
  }

  async trackDownloadThumbnailEvent(payload: TrackDownloadThumbnailEventPayload, options?: ApiOptions): Promise<void> {
    // ここでモック固有の処理を追加できます
    console.log('MockAPIClient: trackDownloadThumbnailEvent called', { payload, options });
    return this.apiClient.trackDownloadThumbnailEvent(payload, options);
  }
}