import React, {ReactElement, useState} from 'react';
import {Download, Loader, X} from 'lucide-react';
import {APIClientError} from "./api/APIClientImpl";
import {IAPIClient} from "./api/APIClient";

/**
 * このコードの残念ポイント
 * 状態管理が雑
 * エラーメッセージの表示がだしi18n対応できない
 * エラーテスト用のコードがコードに混ざっていてちゃんと分離されてない上に処理の切り替えが制御結合
 * UIコンポーネントが全部１ファイルにまとまっていて見通しが良くない
 */

type InputFormState = {
  input: string;
  error?: string;
  isValid: boolean;
};

const InputForm = ({
                     id,
                     state,
                     setState,
                     placeholder,
                     validationPattern,
                     validationErrorMessage,
                     helperText,
                     disabled
                   }: {
  id: string,
  state: InputFormState,
  setState: (state: InputFormState) => void,
  placeholder: string,
  validationPattern: string,
  validationErrorMessage: string,
  helperText: ReactElement<any, any>,
  disabled: boolean
}) => {

  return (
    <div className="grow flex flex-col p-2 ">
      <div className="relative z-0 w-full mb-5 group">
        <input type="text" name={id} id={id}
               className="block py-2.5 px-0 w-full text-sm text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
               value={state.input}
               onChange={(e) => setState({...state, input: e.target.value, isValid: e.target.validity.valid})}
               placeholder=" "
               pattern={validationPattern}
               required
               disabled={disabled}
        />
        <label htmlFor={id}
               className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
          {placeholder}
        </label>
        <p
          className="mt-2 text-sm text-red-400 dark:text-red-500 hidden peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
          <span className="font-medium">{validationErrorMessage}</span>
        </p>
        <p id={`${id}-helper-text-explanation`} className="mt-2 text-sm text-gray-500">{helperText}
        </p>

      </div>
    </div>
  );
};

const Spinner = ({isLoading}: { isLoading: boolean }) => (
  <div className="flex justify-center items-center my-4">
    <Loader className={`text-blue-500 ${isLoading ? 'animate-spin' : 'hidden'}`} size={40}/>
  </div>
);

const ImageGallery = ({state, downloadHandler}: {
  state: ThumbnailProposalState,
  downloadHandler: (arg0: ThumbnailProposalEntry) => void
}) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
      {state.thumbnailProposals.map((image, index) => (
        <div key={index} className="relative group cursor-pointer" onClick={() => downloadHandler(image)}>
          <img
            src={image.thumbnailUrl}
            alt={`Thumbnail ${index + 1}`}
            className="w-full object-cover rounded-lg"
          />
          <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded-lg">
            <p className="text-sm font-bold">{image.score.toFixed(2)}</p>
          </div>
          <div
            className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            <Download size={40} color="white"/>
          </div>
        </div>
      ))}
    </div>
  );
};

const Toast = ({message, onClose}: { message: string, onClose: () => void }) => (
  <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center">
    <span>{message}</span>
    <button onClick={onClose} className="ml-2 focus:outline-none">
      <X size={20}/>
    </button>
  </div>
);

type ThumbnailProposalEntry = {
  id: string;
  thumbnailUrl: string;
  score: number;
}
type ThumbnailProposalState = {
  jobId: string;
  thumbnailProposals: ThumbnailProposalEntry[];
}

type AppState = {
  isLoading: boolean;
  message: string;
  thumbnailProposal: ThumbnailProposalState;
  error: string | null;
  isErrorTestMode: boolean;
  cckInputState: InputFormState;
  worldURLInputState: InputFormState;
};

export default function App({apiClient}: { apiClient: IAPIClient }) {
  const [isLoading, setIsLoading] = useState<AppState['isLoading']>(false);
  const [message, setMessage] = useState<AppState['message']>('');
  const [proposal, setProposal] = useState<AppState['thumbnailProposal']>({jobId: '', thumbnailProposals: []});
  const [error, setError] = useState<AppState['error']>(null);
  const [isErrorTestMode, setIsErrorTestMode] = useState<AppState['isErrorTestMode']>(false);
  const [cckInputState, setCCKInputState] = useState<AppState['cckInputState']>({input: '', isValid: false});
  const [worldURLInputState, setWorldURLInputState] = useState<AppState['worldURLInputState']>({
    input: '',
    isValid: false
  });

  var intervalId = 0
  var lastRequestIssuedAt = 0

  const isDebug = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('debug')?.toLowerCase() === 'true';
  }

  const setTestInput = () => {
    setCCKInputState({input: '88afab3ee33643a9744187ac63c995f404321d194b8e84c86495232fd74e108d', isValid: true});
    setWorldURLInputState({input: 'https://cluster.mu/w/01234567-89ab-cdef-0123-456789abcdef', isValid: true});
  }

  const clearProposal = () => {
    setProposal({jobId: '', thumbnailProposals: []});
  }

  const stopJobStatusCheck = () => {
    clearInterval(intervalId);
  }

  const initiateJobStatusCheck = (jobId: string) => {
    intervalId = +setInterval(() => checkJobStatus(jobId), 3000);
  }

  const checkJobStatus = (jobId: string) => {
    apiClient.getJob(jobId).then((response) => {
      let isJobFinished = response.status === 'success' || response.status === 'failure';
      let hasTimedOut = Date.now() - lastRequestIssuedAt > 30 * 60 * 1000; //30 minutes

      if (hasTimedOut) {
        stopJobStatusCheck();
        setIsLoading(false);
        setError('サムネイルの提案に失敗しました。(タイムアウト)');
        setMessage('');
        return;
      }

      if (!isJobFinished) {
        return;
      } else {
        setIsLoading(false);
        stopJobStatusCheck()
      }

      if (response.status === 'failure') {
        setError('サムネイルの提案に失敗しました。');
        setMessage('');
        return;
      }
      // 成功
      let thumbnailProposalEntries: ThumbnailProposalEntry[] = response.thumbnailProposals.map((thumbnail) => {
        return {
          id: thumbnail.id,
          thumbnailUrl: thumbnail.thumbnailUrl,
          score: thumbnail.score,
        };
      });
      setMessage('サムネイルの提案が完了しました。');
      setError('');
      setProposal({
        jobId: jobId,
        thumbnailProposals: thumbnailProposalEntries
      });
    }).catch((error: APIClientError) => {
      console.error(error);
      // i18n対応させたいし、ここでメッセージを組み立てるのはナンセンスな気がするけど急いでいるのでそのままにする
      switch (error.status) {
        case 401:
          setError('ジョブ状態の取得に失敗しました。APIアクセストークンが無効です。');
          stopJobStatusCheck()
          break;
        case 404:
          setError('ジョブ状態の取得に失敗しました。ジョブが見つかりません。');
          stopJobStatusCheck()
          break;
        default:
          // 次のintervalでリトライされる
          break;
      }
    });
  };

  const extractFilename = (url: string) => {
    const match = url.match(/\/([^\/?#]+)[^\/]*$/);
    return match ? match[1] : '';
  }

  const handleDownload = (thumb: ThumbnailProposalEntry) => {
    let thumbnailUrl = thumb.thumbnailUrl;
    let thumbnailFileName = extractFilename(thumbnailUrl);
    console.log(`Downloading image from: ${thumbnailUrl}`);
    // initiate download
    apiClient
      .trackDownloadThumbnailEvent({jobId: proposal.jobId, thumbnailId: thumb.id})
      .catch((error: APIClientError) => {
        console.error(error);
      });

    fetch(thumbnailUrl)
      .then(response => response.blob())
      .then(blob => {
        // ダウンロード用のリンクを作成
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = thumbnailFileName; // ダウンロードする際のファイル名を指定
        document.body.appendChild(a);
        a.click(); // リンクをクリックしてダウンロードを開始
        a.remove(); // 使用後にリンクを削除
      })
      .catch(error => console.error('Error downloading the image:', error));
  };

  const handleThumbnailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // フォームのバリデーションに失敗していたら何もしない
    if (!cckInputState.isValid || !worldURLInputState.isValid || cckInputState.input === '' || worldURLInputState.input === '') {
      return;
    }
    // WrsIDの取り出し
    const match = worldURLInputState.input.match(/^(https:\/\/cluster.mu\/w\/)?([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/);
    if (!match) {
      setError("URLが無効です")
      return;
    }
    const worldRoomSetId = match[2];

    // ここらへんの状態管理をちゃんとまとめるべきだと思う。あと命名が微妙
    lastRequestIssuedAt = Date.now();
    setIsLoading(true);
    setMessage('サムネイル提案を生成中... 処理には3〜10分程度かかる場合があります。この画面を閉じずにおまちください。');
    clearProposal()
    setError(null);
    apiClient
      .createRequest({
        cckToken: cckInputState.input,
        worldRoomSetId: worldRoomSetId
      }, {prefer: isErrorTestMode ? 'code=401' : ''}) //ゴミ実装ポイント。エラーテスト用のふるまいを制御結合している。
      .then((jobId) => {
        initiateJobStatusCheck(jobId);
        clearProposal()
      }).catch((error: APIClientError) => {
      console.error(error);
      switch (error.status) {
        case 401:
          setError('サムネイル提案の生成に失敗しました。APIアクセストークンが無効か、指定されたワールドにアクセスできません。');
          break;
        case 404:
          setError('サムネイル提案の生成に失敗しました。指定されたワールドが見つかりません。');
          break;
        default:
          setError('サムネイル提案の生成に失敗しました。');
      }

      setMessage('');
      setIsLoading(false);
    });
  };

  const debugComponents = (<div className="mb-4">
    <button
      onClick={() => setIsErrorTestMode(!isErrorTestMode)}
      className={`px-4 py-2 rounded ${
        isErrorTestMode ? 'bg-red-500 text-white' : 'bg-gray-300 text-gray-700'
      }`}
    >
      エラー表示テスト: {isErrorTestMode ? 'ON' : 'OFF'}
    </button>
    <button
      onClick={setTestInput}
      className={`px-4 py-2 rounded bg-green-400 text-black`}
    >
      テスト用入力を設定
    </button>
    <pre>
          {JSON.stringify({
            "isLoaded": isLoading,
            "cckInputState": cckInputState,
            "worldURLInputState": worldURLInputState,
          }, null, 2)}
    </pre>
  </div>)

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">サムネイル提案システム WorldPano α版</h1>
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">概要</h2>
        <p className="text-gray-700 text-sm">
          clusterのワールド作成におけるサムネイルの準備をクリエイターの皆様が効率的に行えるよう、ワールド内部の画像を自動で撮影し、サムネイルのベースとしてお使いいただけるよう提案します。
        </p>
        <p className="text-gray-700 text-sm">
          ワールド作成において、視覚的に魅力的なシーンを構成することは、全体のクオリティや仕上がりを向上させるために欠かせません。キービジュアルやコンセプトアートを参考に、構図や照明、視点の工夫によって印象に残るシーンが求められる中で、WorldPanoはそのような「絵になるシーン」を自動的に発見し、サムネイルとして提案します。シーン構成の検証やアイデアの発展にご活用いただくことで、シーンの美しさを効率的に追求でき、高品質なワールド制作をサポートします。
        </p>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">使用方法</h2>
        <ol className="list-decimal list-outside text-gray-700 text-sm mt-4">
          <li className="ml-4">
            <span>「APIアクセストークン」に事前に発行したトークンを入力します。(WorldPanoにおいてサムネイル提案を実行できるのは、ワールド作成者本人に限定しております。トークンはワールド作成者本人確認のために使用します。)</span>
          </li>
          <li className="ml-4">
            <span>「ワールドURL」にサムネイル提案の画像生成を行いたいワールドのURLを入力します。ただし、ワールドクラフトで作成したワールドには対応していません。</span>
          </li>
          <li className="ml-4">
            <span>「サムネイル提案実行」ボタンをクリックし、生成が完了するまでお待ちください。処理には3〜10分程度かかる場合があります。</span>
          </li>
          <li className="ml-4">
            <span>表示された画像からお好みの画像を選んでダウンロードしてください。</span>
          </li>
        </ol>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">使用のポイント</h2>
        <ol className="list-decimal list-outside text-gray-700 text-sm mt-4">
          <li className="ml-4">
            <span>WorldPanoの動作としては、実際には、絵になるシーンそのものではなく、人間が撮影したくなるようなスポットとアングルを見つけ出し、魅力的な構図を提案します。完全な精度ではありませんが、たくさん試していただき、複数の画像からベストなものを選んでください。</span>
          </li>
          <li className="ml-4">
            <span>提案されたサムネイルは、ダウンロードして自由に加工可能です。タイトルの追加などを行い、サムネイルのベースとしてご活用ください。</span>
          </li>
          <li className="ml-4">
            <span>カメラ位置や設定を細かく調整したい方は、<a href="https://cluster-lab.github.io/panotree/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline hover:text-blue-800">GitHubに公開されているバージョン</a>もぜひご利用ください。</span>
          </li>
        </ol>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">注意事項</h2>
        <ol className="list-decimal list-outside text-gray-700 text-sm mt-4">
          <li className="ml-4">
            <span>WorldPano α版は試験的な機能として公開しており、安定したサービス稼働を保証いたしません。また、事前の通知なくサービスの仕様変更や更新、サービス提供の終了を行うこともあります。</span>
          </li>
          <li className="ml-4">
            <span>WorldPano α版のサムネイル提案機能はワールドクラフトで作成したワールドには対応していません。</span>
          </li>
          <li className="ml-4">
            <span>WorldPano α版はCCKで実装されたギミックおよびClusterScriptが動作しない環境下で画像撮影を行っています。</span>
          </li>
          <li className="ml-4">
            <span>WorldPano α版を利用することで生じた問題については、<a href="https://help.cluster.mu/hc/ja/articles/20264186157337" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline hover:text-blue-800">cluster利用規約</a>に準じることとします。</span>
          </li>
          <li className="ml-4">
            <span>ワールド内にワールド作成者以外の方が著作権等を保有する著作物がある場合に、その著作物が映り込んだ際にサムネイルとして利用可能かどうかは、ワールド作成者ご自身で判断の上ご利用ください。</span>
          </li>
        </ol>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-4">フィードバックについて</h2>
        <p className="text-gray-700 text-sm">
          下記のリンク先のフォームより、フィードバックをお寄せください。
        </p>
        <p className="text-gray-700 text-sm">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfsbXNUA9MuUaRn_gKP96W6aoVQc2dSmNcyoSiPw27Z5l87eQ/viewform" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline hover:text-blue-800">フィードバック投稿フォーム</a>
        </p>
        <p className="text-gray-700 text-sm">
          お気づきの不具合や機能へのご要望、もしくは良かったところ等、ご記入ください。
        </p>
        <p className="text-gray-700 text-sm">
          「提案されたサムネイル候補画像に対する満足度」項目への投票だけでも構いません。
        </p>
      </div>

      <form onSubmit={handleThumbnailSubmit} className="flex-col mb-4">
        <div className="gcol">
          <InputForm
            id='cck-input'
            state={cckInputState}
            setState={setCCKInputState}
            placeholder="APIアクセストークン"
            validationPattern="^[a-fA-F0-9 \t]{64}$"
            validationErrorMessage="16進数の英数字で構成される有効なAPIアクセストークンを入力してください"
            helperText={<span><a className="text-blue-600 underline"
              href="https://cluster.mu/account/tokens"
              target="_blank"
              rel="noopener noreferrer">こちら</a>のページで発行できる"Creator Kit トークン"をご入力ください。</span>}
            disabled={isLoading}
          />
        </div>
        <div className="">
          <InputForm
            id='world-url-input'
            state={worldURLInputState}
            setState={setWorldURLInputState}
            placeholder="ワールドURL"
            validationPattern={"^(https:\/\/cluster\.mu\/w\/)?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"}
            validationErrorMessage="https://cluster.mu/w/ で始まる有効なワールドURLを入力してください"
            helperText={
              <span>https://cluster.mu/w/から始まるワールドのURLを入力してください。ワールドクラフトで作成したワールドには対応していません。</span>
            }
            disabled={isLoading}
          />
        </div>
        <div className="flex mt-4">
          <button
            type="submit"
            className={`gcol w-60 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
              isLoading || !cckInputState.isValid || !worldURLInputState.isValid ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            サムネイル提案実行
          </button>
        </div>
      </form>

      {isDebug() && debugComponents}

      <Spinner isLoading={isLoading}/>
      {message && (
        <div className="mt-4 text-center text-gray-700">{message}</div>
      )}
      <ImageGallery state={proposal} downloadHandler={handleDownload}/>
      {error && (
        <Toast message={error} onClose={() => setError(null)}/>
      )}

      <footer className="mt-4 border-t text-center border-gray-300 pt-2">
        <a href="https://help.cluster.mu/hc/ja/requests/new" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline hover:text-blue-800">お問い合わせ</a>
        <div>© Cluster, Inc.</div>
      </footer>
    </div>
  );
}
