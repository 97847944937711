import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MockAPIClient} from "./api/MockAPIClient";
import {APIClientImpl} from "./api/APIClientImpl";
import {IAPIClient} from "./api/APIClient";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let apiClient: IAPIClient = new MockAPIClient(new APIClientImpl('https://api.worldpano.cluster.mu'));
if (process.env.NODE_ENV == 'production') {
  apiClient = new APIClientImpl('https://api.worldpano.cluster.mu');
}

root.render(
  <React.StrictMode>
    <App apiClient={apiClient} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
